import { useParams } from "react-router";
import React, { useLayoutEffect, useState } from "react";
import FusePageCarded from "@fuse/core/FusePageCarded";
import { IVetModel } from "@fuse/models";
import { Stack } from "@mui/system";
import Alert from "@mui/material/Alert";
import HTMLReactParser from "html-react-parser";
import vetService from "@fuse/services/vetService";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { IApiResponse } from "@fuse/models/user.models";
import VetProfile from "./components/VetProfile/VetProfile";
import VetProfileHeader from "./components/VetProfile/VetProfileHeader";

function VetProfileApp() {
	const params = useParams();
	const siteId = params.id;
	const [error, setError] = useState("");
	const [vet, setVet] = useState<IVetModel>({} as IVetModel);
	const { placesService } = usePlacesService({
		apiKey: process.env.VITE_APP_GOOGLE,
	});

	useLayoutEffect(() => {
		vetService.getSiteById(siteId).then((s: IApiResponse) => {
			setVet(s.result);
		});
	}, []);

	return (
		<React.Fragment>
			<FusePageCarded
				header={<VetProfileHeader vet={vet} />}
				content={
					<>
						<div className="flex w-full p-16 sm:p-24">
							{error && (
								<Stack className="pb-12" sx={{ width: "100%" }} spacing={2}>
									<Alert variant="filled" severity="error" onClose={() => {}}>
										<div>{HTMLReactParser(error)}</div>
									</Alert>
								</Stack>
							)}

							{vet.id && placesService && (
								<VetProfile vet={vet} service={placesService} />
							)}
						</div>
						
					</>
				}
			/>
		</React.Fragment>
	);
}

export default VetProfileApp;
