import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import VetMap from "./VetMap";
import { ICoordinates } from "../../types/vet.types";

function VetProfile({ vet, service }) {
	const params = useParams();
	const siteId = params.id;
	const [coords, setCoords] = useState<ICoordinates>({} as ICoordinates);

	useEffect(() => {
		const detail = service.getDetails(
			{ placeId: vet.googlePlaceId },
			(placeDetails) => {				
				if (placeDetails.photos) {
					const url = placeDetails.photos[0].getUrl({ maxHeight: 300 });

					vet.imageUrl = url;

					vet.address1 = placeDetails.formatted_address;
					setCoords({
						latitude: placeDetails.geometry.location.lat(),
						longitude: placeDetails.geometry.location.lng(),
					});
					vet.phoneNumber = placeDetails.formatted_phone_number;
					vet.rating = +placeDetails.rating;
					vet.imageUrl = url || "assets/img/logo.png";
					if (
						placeDetails.types &&
						placeDetails.types.includes("veterinary_care")
					)
						vet.practiceType = "Veterinary Care";
				}
			}
		);
	}, []);

	return (
		<React.Fragment>
			<>
				<div className="flex w-full flex-col sm:flex-row gap-11">
					<div className="flex w-full md:w-3/5 h-208">
						{vet.id && (
							<Card key={vet.id} className="flex w-full my-5 flex-col">
								<div className="flex w-full">
									<CardMedia
										className="w-112 p-10 rounded-20"
										component="img"
										image={vet.imageUrl || "assets/img/logo.png"}
										alt={vet.name}
									/>
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<CardContent sx={{ flex: "1 0 auto" }}>
											<Typography component="div" variant="h5">
												{vet.name}
											</Typography>
											<Typography
												variant="subtitle1"
												color="text.secondary"
												component="div"
											>
												{vet.practiceType}
											</Typography>
										</CardContent>
									</Box>
								</div>
								<div className="flex w-full flex-col px-24">
									<Typography className="flex w-full">
										{vet.phoneNumber}
									</Typography>
									<Typography className="flex w-full">
										{vet.address1}
									</Typography>
								</div>
							</Card>
						)}
					</div>

					<div className="flex w-full md:w-2/5">
						{coords.latitude && coords.longitude && (
							<VetMap lat={coords.latitude} lng={coords.longitude} />
						)}
					</div>
				</div>
			</>
		</React.Fragment>
	);
}

export default VetProfile;
