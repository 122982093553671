import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
import authRoles from '../auth/authRoles';
import PetProfilesPage from './pet-profiles/PetProfilesPage';
import ChipLookupPage from './chip-lookup/ChipLookupPage';
import HealthCheckPage from './health-check/HealthCheckPage';

const CommonLookupConfig: FuseRouteConfigType = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: true
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: 'microchip-lookup',
			element: <ChipLookupPage />
		},
		{
			path: 'pet-profiles/:id?',
			element: <PetProfilesPage />
		},
		{
			path: 'healthcheck',
			element: <HealthCheckPage />
		}
	]
};

export default CommonLookupConfig;
