import { memo, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { IPetModel } from "@fuse/models";
import { useNavigate } from "react-router";
import { Tooltip, Typography } from "@mui/material";

export interface IImageMap {
	placeId: string;
	url: string;
	address?: string;
	phone?: string
}
/**
 * The TeamMembersWidget widget.
 */
function UserPetList({ pets }) {

	const navigate = useNavigate();
	const petsArray = pets as Array<IPetModel>;

	const handlePetClick = (pet: IPetModel) => {
		try {
			if (pet.isActive) {
				navigate(`/client/pet/update/${pet.id}`);
			} else {
				navigate(`/client/pet/checkout/${pet.id}`);
			}
		} catch (e) {
			console.error(e);
		}
	}

	function getPetImage(pet: IPetModel) {
		if (pet.image) {
			return `${process.env.VITE_APP_API_BASE_URL}Pet/pet-images/${pet.image}`;
		} else if (pet.species?.name) {
			return `assets/icons/pets/${pet.species.name.toLowerCase()}.png`;
		} else {
			return `assets/icons/pets/dog.png`;
		}
	}
	function checkIfPetIsNotActive(pet: IPetModel) {
		return !isChipActive(pet) || pet.subscriptionExpired;
	}

	function isChipActive(pet: IPetModel) {
		return pet.chips && pet.chips.every(f => f.isActive);
	}

	return (
		<div className="flex gap-[20px] overflow-x-auto overflow-y-hidden pt-[14px]">
			{petsArray.map((pet) => (
				<div className="relative" key={pet.id}>
					{
						checkIfPetIsNotActive(pet) && (
							<div className="w-[30px] h-[30px] absolute top-[-14px] right-[-15px] z-10">
								<Tooltip title={pet.subscriptionExpired ? 'Chip Deactivated' : 'Registration Incomplete'}>
									<img className="w-full h-full object-contain" src="assets/img/warning.png" />
								</Tooltip>
							</div>
						)
					}

					<div className="flex flex-col items-center justify-center gap-[12px] cursor-pointer hover:scale-[101%] sm:w-[91px] w-[75px]" key={pet.id} onClick={() => handlePetClick(pet)}>
						<div className="sm:w-[91px] sm:h-[91px] w-[75px] h-[75px] rounded-[8px] overflow-hidden">
							<img className="w-full h-full object-cover" src={getPetImage(pet)} />
						</div>
						<h2 className="font-700 text-[15px] leading-[19.6px] whitespace-nowrap truncate w-full">{pet.name}</h2>
					</div>
				</div>
			))}
		</div>
	);
}

export default memo(UserPetList);
