import FusePageSimple from "@fuse/core/FusePageSimple";
import _ from "@lodash";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Card, Typography } from "@mui/material";
import DashboardAppHeader from "./DashboardAppHeader";
import { IPetModel, IVetModel } from "@fuse/models";
import petService from "@fuse/services/petService";
import CustomerVets from "./components/customer-vets";
import CustomerPets from "./components/customer-pets";
import vetService from "@fuse/services/vetService";
import JwtService from "../../auth/services";
import { useNavigate } from "react-router";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { motion } from "framer-motion";
import CardHeading from "@fuse/core/CardHeading/CardHeading";
import BackupContacts from "./components/backup-contacts";
import userService from "@fuse/services/userService";
import { BackupContactType } from "./types/TeamMemberType";
import CustomOutlinePrimaryButton from "@fuse/core/CustomOutlinePrimaryButton/CustomOutlinePrimaryButton";

const Root = styled(FusePageSimple)(({ theme }) => ({
	"& .FusePageSimple-header": {
		backgroundColor: "#f1f5f9",
		boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
	},
}));

function ProjectDashboardApp() {
	const [pets, setPets] = React.useState<Array<IPetModel>>([]);
	const [vets, setVets] = React.useState<Array<IVetModel>>([]);
	const [contacts, setContacts] = React.useState<Array<BackupContactType>>([]);
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();

	React.useLayoutEffect(() => {
		getAllData();
	}, []);

	async function getAllData() {
		try {
			setLoading(true);
			await Promise.all([
				userService.getBackupContacts(),
				petService.getUserPets(),
				vetService.getUserVets(),
			]).then((res: any) => {
				setContacts(res[0] || []);
				setPets(res[1] || []);
				setVets(res[2] || []);
			});
			setTimeout(() => {
				setLoading(false);
			}, 300);
		} catch (err) {
			setLoading(false);
			console.warn(err);
		}
	}

	function fetchContacts() {
		setLoading(true);
		userService
			.getBackupContacts()
			.then((res: any) => {
				if (res) {
					setContacts(res || []);
				}
				setTimeout(() => {
					setLoading(false);
				}, 300);
			})
			.catch((error) => {
				setTimeout(() => {
					setLoading(false);
				}, 300);
				setContacts([]);
			});
	}

	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col sm:flex-row my-12 gap-[20px]">
				<Card className="flex flex-col w-full md:w-1/2 p-[28px] overflow-hidden gap-[20px]">
					<div className="flex w-full flex-wrap gap-[20px] sm:gap-0">
						{loading && <FuseSplashScreen />}
						<div className="flex w-full md:w-1/2 md:h-[100px]">
							<motion.div
								initial={{ x: 20, opacity: 0 }}
								animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							>
								<CardHeading
									heading="Your Pets"
									customClassHeading="font-quicksand font-700 text-18px sm:text-22px leading-22px sm:leading-26px text-peeva-black"
									subHeading="Click on a pet to manage their photo and details."
									customClassSubHeading="text-16px font-400 tracking-tight leading-22px"
								/>
							</motion.div>
						</div>
						<div className="flex w-full md:w-1/2 sm:justify-end justify-center items-center">
							<CustomOutlinePrimaryButton
								onClick={() => navigate("/client/pet/add")}
							>
								Add a pet
							</CustomOutlinePrimaryButton>
						</div>
					</div>
					<div className={pets && pets.length > 0 ? "sm:h-[143px]" : ""}>
						<CustomerPets pets={pets} />
					</div>
				</Card>
				<Card className="flex flex-col w-full md:w-1/2 p-[28px] overflow-hidden gap-[20px]">
					<div className="flex w-full flex-1 flex-wrap gap-[20px]  sm:gap-0">
						<div className="flex w-full md:w-1/2 flex-1">
							<motion.div
								initial={{ x: 20, opacity: 0 }}
								animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							>
								<CardHeading
									heading="Your Vets"
									customClassHeading="font-quicksand font-700 text-18px sm:text-22px leading-22px sm:leading-26px text-peeva-black"
									subHeading="Click on a vet to view their profile and contact information. "
									customClassSubHeading="text-16px font-400 tracking-tight leading-22px"
								/>
							</motion.div>
						</div>
						<div className="flex w-full md:w-1/2 sm:justify-end justify-center items-center">
							<CustomOutlinePrimaryButton
								onClick={() => navigate("/client/add-vet")}
							>
								Add a vet
							</CustomOutlinePrimaryButton>
						</div>
					</div>
					<div className={pets && pets.length > 0 ? "sm:h-[143px]" : ""}>
						<CustomerVets vets={vets} />
					</div>
				</Card>
			</div>

			<div className="flex flex-col gap-[20px]">
				<Card className="p-[28px] overflow-hidden">
					<BackupContacts contacts={contacts} fetchContacts={fetchContacts} />
				</Card>
			</div>
		</div>
	);
}

export default ProjectDashboardApp;
