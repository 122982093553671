import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAppDispatch } from "app/store";
import _ from "../../../../@lodash/@lodash";
import { IPetModel } from "@fuse/models";
import petService from "@fuse/services/petService";
import { IApiResponse } from "@fuse/models/user.models";
import { PetAlertType } from "./types/PetAlert.type";
import { EmailStats } from "./types/EmailStats.type";
import { Button, Card, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import CustomOutlinePrimaryButton from "@fuse/core/CustomOutlinePrimaryButton/CustomOutlinePrimaryButton";

function PetAlertSent() {
	const param = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [pet, setPet] = useState<IPetModel>({} as IPetModel);
	const [petAlert, setPetAlert] = useState<PetAlertType>({} as PetAlertType);
	const [emailStates, setEmailStates] = useState<EmailStats>(
		{} as EmailStats
	);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const petId = param.id;
		getPetById(petId);
	}, []);

	async function getPetById(id) {
		const data = (await petService.getPetById(id)) as IApiResponse;
		const pet = data.result as any;

		if (pet.image) {
			pet.imageUrl = `${process.env.VITE_APP_API_BASE_URL}Pet/pet-images/${pet.image}`;
		}

		if (pet.dob) {
			pet.dob = new Date(pet.dob);
		}
		if (pet.lastRabiesVaccine) {
			pet.lastRabiesVaccine = new Date(pet.lastRabiesVaccine);
		}

		setPet(pet);
	}

	function getAlertsByPet(petId: string) {
		petService.alertByPet(petId).then((res: IApiResponse) => {
			const petAlert = res.result as PetAlertType;
			if (petAlert) {
				petAlert.isPaid = petAlert.status?.toLocaleLowerCase() === "paid";
				setPetAlert(petAlert);
				getEmailStatus(petAlert.id);
			}
		});
	}

	function getEmailStatus(lpaId: string) {
		petService.getEmailStats(lpaId).then((res: IApiResponse) => {
			setEmailStates(res.result);
		});
	}

	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<Card className="p-[28px] overflow-hidden">
					<motion.div className="flex flex-col w-full container bg-white">
						<div className="flex flex-col flex-auto leading-20px content-start">
							<Typography className="font-quicksand text-18px md:text-20px font-700 leading-28px">
								Lost Pet Alert Sent
							</Typography>
							<p className="font-robotoslab text-14px md:text-16px font-700 leading-18px md:leading-24px mt-16">
								Thank you for trusting Peeva to help bring your pet back home.
							</p>
							<p className="font-robotoslab text-14px md:text-16px font-400 leading-18px md:leading-24px mt-16">
								Your pet’s information has been sent to all pet professionals in
								a 50-mile radius of your pet’s last known location. You will be
								contacted as soon as someone has any information about your pet.
							</p>
						</div>
						<div className="flex flex-col sm:flex-row flex-auto items-center sm:items-start leading-20px gap-10 pt-12">
							<Button
								variant="contained"
								color="secondary"
								className="w-auto max-w-[150px] sm:max-w-xl sm:h-56px h-48px rounded-[32px] bg-peeva-yellow border hover:bg-peeva-yellow-110 transition-colors duration-200 py-[12px] md:py-[16px] px-[24px]  md:px-[32px] sm:text-16px font-700 sm:leading-18px text-14px leading-14px md:leading-24px text-peeva-black"
								aria-label="View Analytics"
								type="submit"
								size="large"
								onClick={() => navigate("/client/add-alert")}
							>
								View Analytics
							</Button>
							
							<CustomOutlinePrimaryButton onClick={() => navigate("/client")}>
								Go to dashboard
							</CustomOutlinePrimaryButton>
						</div>
					</motion.div>
				</Card>
			</div>
		</div>
	);
}

export default PetAlertSent;
