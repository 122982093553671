import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Rating,
	Typography,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddVetDialog from "./AddVetDialog";
import vetService from "@fuse/services/vetService";
import CustomOutlinePrimaryButton from "@fuse/core/CustomOutlinePrimaryButton/CustomOutlinePrimaryButton";

function VetProfileCard({ data, service }) {
	const navigate = useNavigate();
	const [detail, setDetail] = useState({} as any);
	const [showDialog, setShowDialog] = useState(false);
	const [siteId, setSiteId] = useState(null);
	const [error, setError] = useState("");


	useLayoutEffect(() => {
		service.getDetails({ placeId: data.place_id }, (result: any) => {
			if (result) {
				let item = {} as any;
				item.reference = result.reference;
				item.place_id = result.place_id;
				if (result.photos) {
					const url = result.photos[0].getUrl({ maxHeight: 300 });
					item.imageUrl = url;
				}
				item.name = result.name;
				item.address1 = result.formatted_address;
				item.phoneNumber = result.formatted_phone_number;
				item.rating = +result.rating;
				item.reviews = result.reviews?.length || 0;

				if (result.types && result.types.includes("veterinary_care"))
					item.practiceType = "Veterinary Care";

				setDetail(item);
			}
		});
	}, [data]);

	async function handleAddVet(detail: any) {
		let siteId = null;
		try {

			const site = (await vetService.getSiteByGooglePlaceId(
				detail.place_id
			)) as any;
			if (site?.id) {
				siteId = site.id;
			} else {
				const obj = {
					name: detail.name,
					googlePlaceId: detail.place_id,
					isActive: detail.active,
				};
				const addSite = (await vetService.addSite(obj)) as any;
				siteId = addSite.id;
			}
			navigate('/client/add-vet-on-pet', { state: detail });



		} catch (e) {
			console.error(e);
		}

		if (siteId) {
			setSiteId(siteId);
			setShowDialog(true);
		} else {
			setError("Unable to create site for provided place");
		}
	}

	function handleClose() {
		setSiteId(null);
		setShowDialog(false);
	}

	return (
		<>
			<Card className="p-[28px] flex sm:flex-row flex-col gap-[20px] w-full">
				<div className="flex gap-[12px] w-full">
					<div className="flex h-[95px] w-[95px] overflow-hidden rounded-[8px]">
						<img className="w-full h-full object-cover" src={detail.imageUrl} alt={detail.name} />
					</div>
					<div className="flex flex-col gap-[4px] w-full">
						<h2 className="font-700 sm:text-[14px] sm:leading-[19.6px] text-[12px] leading-[16.8px]">{detail.name}</h2>
						<div className="flex sm:gap-[8px] gap-[4px]">
							<Rating name='vet-rating' value={detail.rating || 0} precision={0.1} readOnly className="text-peeva-blue" />
							<p className="font-400 text-[12px] leading-[16.8px] text-peeva-black-110">{detail.reviews} reviews</p>
						</div>
						<p className="font-400 sm:text-[14px] sm:leading-[21px] text-[12px] leading-[16.8px] text-peeva-black-110">{detail.phoneNumber}</p>
						<p className="font-400 sm:text-[14px] sm:leading-[21px] text-[12px] leading-[16.8px]  text-peeva-black-110">{detail.address1}</p>
					</div>
				</div>
				<div className="flex sm:justify-end justify-center sm:items-start items-center w-full">
					<CustomOutlinePrimaryButton onClick={() => handleAddVet(detail)}>Add vet</CustomOutlinePrimaryButton>
				</div>
			</Card>

			{/* <div className="flex w-full flex-col sm:flex-row gap-11">
				<div className="flex w-full py-3">
					<Card className="flex w-full my-5 py-10 flex-col">
						<div className="flex w-full">
							<CardMedia
								className="w-112 min-h-112 max-h-160 p-10 rounded-20"
								component="img"
								image={detail.imageUrl}
								alt={detail.name}
							/>
							<div className="flex w-full">
								<CardContent className="flex w-full flex-col">
									<Typography
										component="div"
										variant="h5"
										className="flex flex-row justify-between"
									>
										{detail.name}
										<Button
											variant="outlined"
											onClick={() => handleAddVet(detail)}
											className="mt-4 sm:h-56px h-48px min-w-144 rounded-16 bg-peeva-blue hover:bg-peeva-blue-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px border-0"
										>
											Add Vet
										</Button>
									</Typography>
									<Typography
										variant="subtitle1"
										color="text.secondary"
										component="div"
									>
										{detail.practiceType}
									</Typography>
									<Typography
										component="legend"
										className="flex gap-10 items-center mt-10"
									>
										<Rating
											name="read-only"
											value={detail.rating || 0}
											readOnly
										/>{" "}
										{detail.reviews} Reviews
									</Typography>
								</CardContent>
							</div>
						</div>
						<div className="flex w-full flex-col px-24 pt-10">
							<Typography className="flex w-full">
								<PhoneIcon fontSize="small" className="mr-10" />{" "}
								{detail.phoneNumber}
							</Typography>
							<Typography className="flex w-full mt-10">
								<LocationOnIcon fontSize="small" className="mr-10" />{" "}
								{detail.address1}
							</Typography>
						</div>
					</Card>
				</div>
			</div>
			{showDialog && siteId && (
				<AddVetDialog siteId={siteId} handleClose={handleClose} />
			)} */}
		</>
	);
}

export default VetProfileCard;
