import _ from "../../../../../../@lodash/@lodash";
import FusePageCarded from "@fuse/core/FusePageCarded";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import EditPetHeader from "./UpdatePetProfileHeader";
import { useEffect, useState } from "react";
import utilityService from "@fuse/services/utilityService";
import petService from "@fuse/services/petService";
import { useAppDispatch, useAppSelector } from "app/store";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { IPetModel, IVetModel } from "@fuse/models";
import UpdatePetForm from "./UpdatePetProfileForm";
import { PetUpdateType } from "../../types/PetUpdateType";
import { selectUser } from "app/store/user/userSlice";
import PetOwnerApp from "./PetOwnerApp";
import PetVetsListing from "./PetVetsListing";
import vetService from "@fuse/services/vetService";
import { Card } from "@mui/material";
import FuseLoading from "@fuse/core/FuseLoading";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";


const container = {
	show: {
		transition: {
			staggerChildren: 0.06,
		},
	},
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

/**
 * The analytics dashboard app.
 */
function UpdatePetProfileApp() {
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
	const user = useAppSelector(selectUser);
	const navigator = useNavigate();
	const param = useParams();
	const id = param.id;
	const dispatch = useAppDispatch();
	const [breeds, setBreeds] = useState([]);
	const [allSpecies, setSpecies] = useState([]);
	const [allBrands, setBrands] = useState([]);
	const [pet, setPet] = useState<IPetModel>({} as IPetModel);
	const [vets, setVets] = useState<IVetModel[]>([] as IVetModel[]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const { placesService } = usePlacesService({
		apiKey: process.env.VITE_APP_GOOGLE,
	});

	useEffect(() => {
		utilityService
			.getStaticData()
			.then((res: any) => {
				if (res.result) {
					setSpecies(res.result.species);
					setBrands(res.result.brands);
				}
			})
			.catch((error) => {
				console.log(error);
			});

		utilityService
			.getPetBreedsByPetId(id)
			.then((res: any) => {
				if (res.result) {
					setBreeds(res.result);
				}
			})
			.catch((error) => {
				console.log(error);
			});

		petService
			.getPetById(id)
			.then((res: any) => {
				if (res.result) {
					const pet = res.result;
					if (pet.image) {
						pet.imageUrl = `${process.env.VITE_APP_API_BASE_URL}Pet/pet-images/${pet.image}`;
					}
					if (pet.dob) {
						pet.dob = new Date(pet.dob);
					}
					if (pet.lastRabiesVaccine) {
						pet.lastRabiesVaccine = new Date(pet.lastRabiesVaccine);
					}
					setPet(pet);
				}
			})
			.catch((error: string[]) => {
				setError(error.join("<br />"));
				setTimeout(() => {
					setError("");
				}, 10000);
			});

		vetService
			.getUserVets()
			.then((res: any) => {
				if (res) {
					setVets(res);
				}
			})
			.catch((error) => {
				setVets([]);
			});
	}, []);

	function updatePet(data: PetUpdateType) {
		setProcessing(true);
		data.id = id;
		if (!data.dob) {
			data.dob = null;
		} else {
			data.dob = moment(data.dob).format("YYYY-MM-DD");
		}

		if (!data.lastRabiesVaccine) {
			data.lastRabiesVaccine = null;
		} else {
			data.lastRabiesVaccine = moment(data.lastRabiesVaccine).format(
				"YYYY-MM-DD"
			);
		}

		data.breeds = [];

		if (data.petBreedList?.length > 0) {
			data.petBreedList.forEach((f) => {
				data.breeds.push({
					id: f,
					name: f,
				});
			});
		}

		data.image = data.imageUrl?.indexOf(";") > 0 ? data.imageUrl : pet.image;

		petService
			.updatePetProfile(data)
			.then((res: IPetModel) => {
				setProcessing(false);
				if (res.id) {
					navigator(`/`);
				}
			})
			.catch((error: string[]) => {
				setProcessing(false);
				setError(error.join("<br />"));

				setTimeout(() => {
					setError("");
				}, 10000);
			});
	}

	if (!pet.id)
		return (
			<div className="w-full h-full">
				{<FuseLoading  />}
			</div>
		);
	else
		return (
			<div className="w-full p-[20px]">
				<div className="flex flex-col gap-[20px]">
					<Card className="p-[18px] md:p-[28px]">
						{pet.id && (
							<UpdatePetForm
								key={pet.id}
								pet={pet}
								breeds={breeds}
								allSpecies={allSpecies}
								allBrands={allBrands}
								onUpdate={updatePet}
								loading={loading}
								processing= {processing}
							/>
						)}
					</Card>
				</div>
				<div className="flex flex-col md:flex-row gap-[20px]">
					<div className="flex w-full md:w-1/2 gap-[20px] mt-12">
						<Card className="p-[18px] md:p-[28px] w-full md:h-[14em]">
							{user?.data && (
								<PetOwnerApp key={user.data?.displayName} user={user} />
							)}
						</Card>
					</div>
					<div className="flex w-full md:w-1/2 gap-[20px] mt-12">
						<Card className="p-[18px] md:p-[28px] w-full">
							{vets && placesService && <PetVetsListing vets={vets} service={placesService} />}
						</Card>
					</div>
				</div>
			</div>
		);
}

export default UpdatePetProfileApp;
