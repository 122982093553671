import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
import SignUpHome from './SignUpHome';
import authRoles from '../../authRoles';
import SignUpForm from './SignUpForm';
import PetRegisterForm from '../PetFlow/PetRegisterForm';

const SignUpFlowConfig: FuseRouteConfigType = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: 'sign-up',
			element: <SignUpHome />
		},
		{
			path: 'sign-up-form',
			element: <SignUpForm />
		}
	]
};

export default SignUpFlowConfig;
