import FuseUtils from "@fuse/utils/FuseUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
	IApiResponse,
	IForgotPasswordResponse,
} from "@fuse/models/user.models";

class CommonService {
	/**
	 * Signs in with the provided email and password.
	 */
	searchChip = (chip: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL}Utilities/AAHALookup?id=${chip}`
				)
				.then((response: AxiosResponse<IForgotPasswordResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getHealthStatus = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}utilities/health-check`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});
}

const instance = new CommonService();

export default instance;
