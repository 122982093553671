import axios, { AxiosResponse } from "axios";
import {
	IApiResponse,
	IForgotPasswordResponse,
} from "@fuse/models/user.models";
import { IVetModel } from "@fuse/models";

class VetService {
	getUserVets = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}site/for-user`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getSiteById = (siteId) =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}site/${siteId}`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getSiteByGooglePlaceId = (placeId) =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}site/ByGoogleId/${placeId}`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	addSite = (obj) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}site/create`, obj)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getSitesForUserPets = (siteId) =>
		new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL}site/sites-for-user-pets/${siteId}`
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	addSiteToPets = (obj) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}site/add-site-to-pets`, obj)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});
}
const instance = new VetService();

export default instance;
