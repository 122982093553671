import lazyWithReducer from 'app/store/lazyWithReducer';
import VetDetailApp from './components/VetDetailApp';
import AddVetApp from './components/VetSearch/AddVetApp';
import VetProfileApp from './VetProfileApp';
import AddVetOnPet from './components/AddVetOnPet/AddVetOnPet';
import VetInfo from './components/VetInfo/VetInfo';

/**
 * The analytics dashboard app config.
 */
const AddVetAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: 'client/add-vet',
			element: <AddVetApp />
		},
		{
			path: 'client/add-vet-on-pet',
			element: <AddVetOnPet />
		},
		{
			path: 'client/vet-info/profile/:id',
			element: <VetInfo />
		},
		{
			path: 'client/add-vet/:petId',
			element: <AddVetApp />
		},
		{
			path: 'client/vet-detail/:id',
			element: <VetDetailApp />
		},
		{
			path: 'client/vet/profile/:id',
			element: <VetProfileApp />
		},
		{
			path: 'client/vet/profile/:id',
			element: <VetProfileApp />
		}
	]
};

export default AddVetAppConfig;
