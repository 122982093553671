import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import CloseIcon from "@mui/icons-material/Close";
import utilityService from "@fuse/services/utilityService";
import {
	Avatar,
	Box,
	Link,
	Button,
	Switch,
	SwitchProps,
	TextareaAutosize,
	Typography,
	styled,
} from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import CardHeading from "@fuse/core/CardHeading/CardHeading";
import FieldLabel from "@fuse/core/FieldLabel/FieldLabel";
import { BreedsType } from "../../types/BreedType";
import _ from "lodash";
import { DatePicker } from "@mui/x-date-pickers";
import SettingsIcon from "@mui/icons-material/Settings";
import moment from "moment";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectUser, selectUserData } from "app/store/user/userSlice";
import petService from "@fuse/services/petService";

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
	"& .MuiAutocomplete-inputRoot": {
		borderRadius: 28,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		fontSize: 16,
		padding: "6px 16px",
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		boxShadow: "none",
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 28,
			borderColor: "#000",
			boxShadow: "none",
		},
	},
	".Mui-focused": {
		boxShadow: "none",
	},
}));

const BootstrapSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#52BFF4",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#9DC1Fc" : "#9DC1Fc",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#52BFF4",
			border: "6px solid #52BFF4",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#EEE" : "#EEE",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const schema = yup.object().shape({
	name: yup
		.string()
		.required("Pet Name is required")
		.min(3, "The Pet name must be at least 3 characters"),
	imageUrl: yup.string().nullable().optional(),
	microchip1: yup.string().optional(),
	speciesId: yup
		.string()
		.required("Pet Species is required")
		.min(1, "Pet Species is required"),

	petBreedList: yup.array().of(yup.string()).optional(),
	sex: yup.number().optional(),
	dob: yup.date().nullable().optional(),
	lastRabiesVaccine: yup.date().nullable().optional(),
	spayedNeutered: yup.boolean().optional(),
	approximateWeight: yup.number().nullable().optional(),
	notes: yup.string().nullable().optional(),
});
/**
 * The analytics dashboard app.
 */
function UpdatePetProfileForm({
	pet,
	breeds,
	allSpecies,
	allBrands,
	onUpdate,
	loading,
	processing,
}) {
	const container = {
		show: {
			transition: {
				staggerChildren: 0.06,
			},
		},
	};

	const petBreedList = pet.petBreedList.map((m) => m.id);
	const chip = pet.chips?.length > 0 ? pet.chips[0].chipId : "";
	const species = allSpecies.find((f) => f.id === pet.speciesId);

	const userData = useSelector(selectUserData);
	const user = useSelector(selectUser);
	const [hasSubscription, setHasSubscription] = useState(false);
	const [isOneTime, setIsOneTime] = useState(true);
	const [dashboardUrl, setDashboardUrl] = useState("");
	const [count, setCount] = useState(0);

	const [showImageMessage, setShowImageMessage] = useState(false);
	const [apiLoading, setApiLoading] = useState(false);
	const [notesLength, setNotesLength] = useState(0);
	const [allBreeds, setBreeds] = useState(breeds);
	const [drag, setDrag] = useState(false);

	var sexList = [
		{ id: 0, name: "Unknown" },
		{ id: 1, name: "Male" },
		{ id: 2, name: "Female" },
	];
	let zero: number = 0;
	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			name: pet.name,
			microchip1: chip,
			speciesId: pet.speciesId || "",
			petBreedList: petBreedList || [],
			dob: pet.dob ? moment(pet.dob).toDate() : null,
			lastRabiesVaccine: pet.lastRabiesVaccine
				? moment(pet.lastRabiesVaccine).toDate()
				: null,
			sex: pet.sex,
			imageUrl: pet.imageUrl,
			spayedNeutered: pet.spayedNeutered ?? false,
			approximateWeight: pet.approximateWeight ?? 0,
			notes: pet.notes ?? "",
		},
		resolver: yupResolver(schema),
	});
	const {
		control,
		watch,
		trigger,
		reset,
		handleSubmit,
		formState,
		getValues,
		setValue,
	} = methods;
	const { errors, isValid, dirtyFields } = formState;

	const notes = watch("notes");
	const speciesId = watch("speciesId");

	useLayoutEffect(() => {
		if (userData) {
			const { haveActivePets, haveSubscriptions, subscriptionUrl } = userData;

			if (haveSubscriptions) {
				setHasSubscription(true);
				setDashboardUrl(subscriptionUrl);
			}
		}

		const isOneTimeSubscription =
			pet.chips?.length > 0 ? pet.chips[0].oneTime : true;

		setIsOneTime(isOneTimeSubscription);
	}, [userData]);

	useEffect(() => {
		setNotesLength(notes?.length);
	}, [notes]);

	useEffect(() => {
		utilityService
			.getPetBreedsByPetId(pet.id)
			.then((res: any) => {
				if (res.result) {
					setBreeds(res.result);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const dragOver = (e) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragEnter = (e) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragLeave = (e) => {
		e.preventDefault();
		setDrag(false);
	};

	const fileDrop = (e, onChange) => {
		e.preventDefault();
		setDrag(false);
		const files = e.dataTransfer.files;
		if (files.length) {
			if (files.length > 1) {
				//TODO: Show Alert here
			}
			handleFile(files[0], onChange);
		}
	};

	const handleFile = (file, onChange) => {
		if (!file.type.startsWith("image/")) {
			alert("Only image files can be uploaded");
			return;
		}
		const reader: FileReader = new FileReader();
		reader.onload = () => {
			if (typeof reader.result === "string") {
				onChange(`data:${file.type};base64,${btoa(reader.result)}`);
			} else {
				// TODO: SHOW ERROR
			}
		};
		reader.onerror = () => {
			// TODO: SHOW ERROR
		};
		reader.readAsBinaryString(file);
	};
	function onSubmit(data: any) {
		onUpdate(data);
	}

	function onSpeciesChange(speciesId) {
		setBreeds([]);
		setValue("petBreedList", []);

		setApiLoading(true);

		utilityService
			.getPetBreedsBySpeciesId(speciesId)
			.then((res: any) => {
				if (res.result) {
					setBreeds(res.result);
					setApiLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				setApiLoading(false);
			});
	}

	function getRegistrationText() {
		if (!pet.isActive || pet.chips.length == 0) {
			return "Not registered";
		}
		var chip = pet.chips[0];
		if (chip.expirationDate) {
			return `Registered Until: ${moment(chip.expirationDate).format(
				"MMM DD, YYYY"
			)}`;
		} else {
			return `Registered Until: Lifetime`;
		}
	}

	async function updateSubscription() {
		const result = (await petService.upgradePetPlan(pet.id)) as any;
		window.location.href = result.location;
	}

	function gotoStripeDashhoard() {
		window.open(
			`${process.env.VITE_APP_API_BASE_URL}utilities/stripe-dashboard?uid=${user.id}`,
			"_blank"
		);
	}

	return (
		<>
			{(loading || processing || apiLoading) && <FuseSplashScreen />}
			<div className="flex flex-col gap-[20px]">
				<div className="flex justify-between flex-col md:flex-row gap-10 md:gap-0">
					<>
						<CardHeading
							heading="Pet Information"
							customClassHeading="font-robotoslab text-18px md:text-22px font-700 leading-22px md:leading-26px"
						/>

						<motion.div
							className="flex flex-row items-center gap-[20px] "
							variants={container}
							initial="hidden"
							animate="show"
						>
							{!isOneTime && (
								<Button
									variant="outlined"
									className="bg-peeva-yellow hover:bg-peeva-yellow-110 text-white"
									onClick={() => updateSubscription()}
								>
									Upgrade Subscription to Lifetime
								</Button>
							)}
							{hasSubscription && (
								<Link
									onClick={() => gotoStripeDashhoard()}
									target="_blank"
									className="text-peeva-blue"
									sx={{ cursor: "pointer", textDecoration: "none" }}
								>
									<Typography className="font-robotoslab text-16px font-700 leading-22px">
										<SettingsIcon className="mr-2" /> Manage Subscriptions
									</Typography>
								</Link>
							)}
						</motion.div>
					</>
				</div>
				<div className="flex justify-between flex-col md:flex-row">
					<CardHeading
						subHeading={getRegistrationText()}
						customClassSubHeading="font-robotoslab text-14px md:text-16px font-700 leading-20px md:leading-22px"
					/>
				</div>

				<form
					name="registerForm"
					noValidate
					className="flex w-full flex-col justify-center overflow-y-scroll gap-[20px]"
					id="signupform-form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="flex sm:flex-row flex-col w-full gap-[20px]">
						<div className="flex sm:justify-start align-start justify-center">
							<Controller
								control={control}
								name="imageUrl"
								render={({ field: { onChange, value } }) => (
									<Box
										className="relative flex items-center justify-center w-[200px] h-[200px] rounded-xl overflow-hidden border-dashed border-[2px] border-black"
										onDragOver={dragOver}
										onDragEnter={dragEnter}
										onDragLeave={dragLeave}
										onDrop={(e) => fileDrop(e, onChange)}
									>
										{/* <div className="absolute inset-0 bg-black bg-opacity-50 z-10" /> */}
										<div className="absolute inset-0 flex items-center justify-center z-20 w-full h-full cursor-pointer p-[40px_20px]">
											<div>
												<label
													htmlFor="pet-image"
													className="flex cursor-pointer"
												>
													<input
														accept="image/*"
														className="hidden"
														id="pet-image"
														type="file"
														onChange={async (e) => {
															function readFileAsync() {
																return new Promise((resolve, reject) => {
																	const file = e?.target?.files?.[0];
																	if (!file) {
																		return;
																	}
																	const reader: FileReader = new FileReader();

																	reader.onload = () => {
																		if (typeof reader.result === "string") {
																			resolve(
																				`data:${file.type};base64,${btoa(
																					reader.result
																				)}`
																			);

																			const base64String = `data:${
																				file.type
																			};base64,${btoa(reader.result)}`;
																			var stringLength =
																				base64String.length -
																				"data:image/png;base64,".length;

																			var sizeInBytes =
																				4 *
																				Math.ceil(stringLength / 3) *
																				0.5624896334383812;
																			var sizeInMb =
																				sizeInBytes / (1000 * 1000);
																			if (sizeInMb > 2) {
																				setShowImageMessage(true);
																			} else {
																				setShowImageMessage(false);
																			}
																		} else {
																			reject(
																				new Error(
																					"File reading did not result in a string."
																				)
																			);
																		}
																	};
																	reader.onerror = reject;
																	reader.readAsBinaryString(file);
																});
															}
															const newImage = await readFileAsync();
															onChange(newImage);
														}}
													/>
													<FuseSvgIcon className="text-white opacity-10 text-opacity-0 w-full h-full">
														heroicons-outline:camera
													</FuseSvgIcon>
												</label>
											</div>
											<div className="absolute top-10 right-6 cursor-pointer">
												<CloseIcon
													className="text-32"
													onClick={() => {
														onChange("");
													}}
												/>
											</div>
										</div>
										<Avatar
											sx={{
												backgroundColor: "background.default",
												color: "text.secondary",
												borderRadius: 0,
											}}
											className="object-cover w-full h-full text-center text-[12px] font-700 leading-[24px]"
											src={value}
											alt={yup?.Schema?.name}
										>
											Drag and drop or click to upload your profile photo
										</Avatar>
									</Box>
								)}
							/>
						</div>
						<div className="flex flex-col w-full">
							<div className="flex sm:flex-row flex-col w-full md:gap-[20px]">
								<div className="flex w-full flex-col">
									<FieldLabel>Pet Name</FieldLabel>
									<Controller
										name="name"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												sx={{ border: "solid 2px black" }}
												required
												aria-label="Pet Name"
												autoFocus
												id="name"
												variant="outlined"
												fullWidth
												error={!!errors.name}
												helperText={errors?.name?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col">
									<FieldLabel>Birth Date</FieldLabel>
									<Controller
										name="dob"
										control={control}
										render={({ field }) => (
											<DatePicker
												{...field}
												format="MM/dd/yyyy"
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												autoFocus
												sx={{ border: "solid 2px black" }}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:flex-row flex-col w-full md:gap-[20px]">
								<div className="flex w-full flex-col">
									<FieldLabel>Microchip Number(s)</FieldLabel>
									<Controller
										name="microchip1"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-gray placeholder-gray"
												id="chipId"
												variant="outlined"
												fullWidth
												sx={{
													border: "solid 2px black",
													"& input[readonly]": {
														color: "#9E9E9E",
													},
												}}
												error={!!errors?.microchip1}
												helperText={errors?.microchip1?.message as string}
												InputProps={{
													readOnly: true,
												}}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col">
									<FieldLabel>Last Rabies Vaccine</FieldLabel>
									<Controller
										name="lastRabiesVaccine"
										control={control}
										render={({ field }) => (
											<DatePicker
												{...field}
												format="MM/dd/yyyy"
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												autoFocus
												sx={{ border: "solid 2px black" }}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:flex-row flex-col w-full md:gap-[20px]">
								<div className="flex w-full flex-col">
									<FieldLabel>Approximate Weight (lbs)</FieldLabel>
									<Controller
										name="approximateWeight"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												type="number"
												inputProps={{
													step: 1.0, // This allows decimal inputs
												}}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="approximateWeight"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors?.approximateWeight}
												helperText={
													errors?.approximateWeight?.message as string
												}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col">
									<FieldLabel>Species</FieldLabel>
									<Controller
										name="speciesId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<BootstrapAutoComplete
												// onChange={onChange}
												onChange={(
													event: any,
													newValue: { id: string; name: string }
												) => {
													onChange(newValue?.id);
													if (newValue?.id) {
														onSpeciesChange(newValue?.id);
													}
												}}
												value={value || null}
												autoFocus
												options={allSpecies}
												className="mb-20"
												// groupBy={(option: any) => option?.country}
												getOptionLabel={(option: any) =>
													typeof option == "object"
														? option
															? option?.name
															: ""
														: allSpecies.find((c) => c?.id == option)?.name ??
														  ""
												}
												renderInput={(params: any) => {
													return (
														<TextField
															{...params}
															autoFocus
															placeholder="Please Select"
															id="speciesId"
															variant="standard"
															fullWidth
															InputProps={{
																...params.InputProps,
																disableUnderline: true,
															}}
															error={!!errors.speciesId}
															helperText={errors?.speciesId?.message as string}
														/>
													);
												}}
											/>
										)}
									/>
								</div>
							</div>
							<div className="flex sm:flex-row flex-col w-full md:gap-[20px]">
								<div className="flex w-full flex-col">
									<FieldLabel>Breed(s) - Add up to 3</FieldLabel>
									<Controller
										name="petBreedList"
										control={control}
										render={({ field: { onChange, value } }) => (
											<BootstrapAutoComplete
												multiple
												freeSolo
												options={allBreeds}
												className="mb-20"
												getOptionLabel={(option: any) => option?.name || ""}
												onChange={(event, newValue: any) => {
													if (newValue.length <= 3) {
														onChange(newValue.map((item) => item?.id));
													}
												}}
												value={
													value
														? ((value as any[]).map((id) =>
																_.find(allBreeds, { id })
														  ) as BreedsType)
														: ([] as BreedsType)
												}
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder="Select Breeds"
														variant="outlined"
														InputLabelProps={{
															shrink: true,
														}}
														error={!!errors.petBreedList}
														helperText={errors?.petBreedList?.message as string}
													/>
												)}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Sex</FieldLabel>
									<Controller
										name="sex"
										control={control}
										defaultValue={zero}
										render={({ field: { onChange, value } }) => (
											<BootstrapAutoComplete
												// onChange={onChange}
												onChange={(
													event: any,
													newValue: { id: string; name: string }
												) => {
													onChange(newValue?.id);
												}}
												value={value || null}
												autoFocus
												options={sexList}
												className="mb-20"
												// groupBy={(option: any) => option?.country}
												getOptionLabel={(option: any) =>
													isNaN(option)
														? option
															? option?.name
															: ""
														: sexList.find((c) => c?.id == option)?.name ?? ""
												}
												renderInput={(params: any) => {
													return (
														<TextField
															{...params}
															autoFocus
															placeholder="Please Select"
															id="sex"
															variant="standard"
															fullWidth
															InputProps={{
																...params.InputProps,
																disableUnderline: true,
															}}
															error={!!errors.sex}
															helperText={errors?.sex?.message as string}
														/>
													);
												}}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:w-1/2 w-full items-center">
								<Controller
									name="spayedNeutered"
									control={control}
									render={({ field: { onChange, value } }) => (
										<div className="flex justify-between items-center h-full w-full mb-20">
											<FieldLabel>Spayed/neutered</FieldLabel>
											<BootstrapSwitch
												checked={value}
												onChange={(ev) => {
													onChange(ev.target.checked);
												}}
												aria-label="spayed or neutered?"
											/>
										</div>
									)}
								/>
							</div>
							<div className="flex w-full flex-col sm:gap-[8px] gap-[4px]">
								<FieldLabel>Pet Notes</FieldLabel>
								<Controller
									name="notes"
									control={control}
									render={({ field }) => (
										<TextareaAutosize
											{...field}
											minRows={5}
											maxRows={5}
											onChange={(event) => {
												if (event.target.value?.length <= 200) {
													field.onChange(event);
												}
											}}
											className=" bg-white rounded-[20px] p-[8px] border-[2px] border-black h-[44px] sm:h-[102px] text-14px sm:text-[16px] font-400 sm:font-400 leading-22px sm:leading-[24px] text-black placeholder-black"
											autoFocus
											id="notes"
										/>
									)}
								/>
								<p className="text-400 font-[14px] leading-[17.5px] w-full text-end pr-10">
									{notesLength}/200
								</p>
							</div>
						</div>
					</div>
					<div className="flex sm:justify-end w-full sm:w-auto justify-center">
						<div className="flex flex-col items-end">
							{processing && (
								<motion.div
									className="max-w-[200px]"
									initial={{ opacity: 0, x: 20 }}
									animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
								>
									<CustomPrimaryButton disabled={true}>
										Saving ...
									</CustomPrimaryButton>
								</motion.div>
							)}
							{processing && showImageMessage && (
								<Typography component="span" variant="body2" gutterBottom>
									If you've used a high-quality image for your pet, this may
									take a minute!
								</Typography>
							)}
						</div>
						{!processing && (
							<CustomPrimaryButton
								onClick={(e) => {
									trigger();
								}}
							>
								Save
							</CustomPrimaryButton>
						)}
					</div>
				</form>
			</div>
		</>
	);
}

export default UpdatePetProfileForm;
