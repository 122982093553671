import Typography from "@mui/material/Typography";
import { memo, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { IVetModel } from "@fuse/models";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useNavigate } from "react-router";

export interface IImageMap {
	placeId: string;
	url: string;
	address?:string;
	phone?:string
}

/**
 * The TeamMembersWidget widget.
 */
function CustomerVets(props) {
	const navigate = useNavigate();
	const [imageMap, setImageMap] = useState(Array<IImageMap>);
	const vets = props.vets as Array<IVetModel>;
	const { placesService } = usePlacesService({
		apiKey: process.env.VITE_APP_GOOGLE,
	});

	useEffect(() => {
		vets
			.filter((f) => f.googlePlaceId)
			.forEach((element) => {
				const detail = placesService.getDetails(
					{ placeId: element.googlePlaceId },
					(placeDetails) => {
						if (placeDetails.photos) {
							const url = placeDetails.photos[0].getUrl({ maxHeight: 300 });
							if (!imageMap.find((f) => f.placeId == element.googlePlaceId)) {
								setImageMap([
									...imageMap,
									{ placeId: element.googlePlaceId, url },
								]);
							}
						}
					}
				);
			});
	});

	const container = {
		show: {
			transition: {
				staggerChildren: 0.05,
			},
		},
	};

	const item = {
		hidden: { opacity: 0, y: 20 },
		show: { opacity: 1, y: 0 },
	};

	function getVetImage(vet: IVetModel) {
		if (vet?.googlePlaceId) {
			return (
				imageMap.find((f) => f.placeId === vet.googlePlaceId)?.url ||
				"assets/img/logo.png"
			);
		} else {
			return "assets/img/logo.png";
		}
	}

	return (
		<div className="flex gap-[20px] overflow-x-auto overflow-y-hidden">
			{vets.map((vet) => (
				<div className="flex flex-col items-center justify-center gap-[12px] cursor-pointer hover:scale-[101%] sm:w-[91px] w-[75px] py-10" key={vet.id} onClick={() => navigate(`/client/vet-info/profile/${vet.id}`)}>
					<div className="sm:w-[91px] sm:h-[91px] w-[75px] h-[75px] rounded-[8px] overflow-hidden border">
						<img className={`w-full h-full object-cover p-4 border `} src={getVetImage(vet)} />
					</div>
					<h3 className="font-700 text-12px md:text-[14px] leading-17px md:leading-20px whitespace-nowrap truncate w-full text-center">{vet.name}</h3>
				</div>
			))}
		</div>		
	);
}

export default memo(CustomerVets);
