import _ from "../../../../../../@lodash/@lodash";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Rating,
	Typography,
	useTheme,
} from "@mui/material";
import { IVetModel } from "@fuse/models";
import vetService from "@fuse/services/vetService";
import { IImageMap } from "../../../dashboard/components/customer-vets";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Link } from "react-router-dom";
import { width } from "@mui/system";

const container = {
	show: {
		transition: {
			staggerChildren: 0.06,
		},
	},
};

const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

/**
 * The analytics dashboard app.
 */
function PetVetsListing({ vets, service }) {
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
	const theme = useTheme();

	const [imageMap, setImageMap] = useState(Array<IImageMap>);

	const param = useParams();
	const petId = param.id;
	const navigate = useNavigate();

	useEffect(() => {
		populateVetImages(vets || []);
	}, [service, vets]);

	function populateVetImages(vetsList: Array<IVetModel>) {
		if (!service) return;
		vetsList
			.filter((f) => f.googlePlaceId)
			.forEach((element) => {
				const detail = service.getDetails(
					{ placeId: element.googlePlaceId },
					(placeDetails) => {
						if (placeDetails.photos) {
							const url = placeDetails.photos[0].getUrl({ maxHeight: 300 });

							if (!imageMap.find((f) => f.placeId == element.googlePlaceId)) {
								setImageMap([
									...imageMap,
									{
										placeId: element.googlePlaceId,
										url,
										address: placeDetails.formatted_address,
										phone: placeDetails.formatted_phone_number,
									},
								]);
								element.address1 = placeDetails.formatted_address;

								element.phoneNumber = placeDetails.formatted_phone_number;
								element.rating = +placeDetails.rating;
								element.reviews = placeDetails.reviews ? placeDetails.reviews.length : 0;
								element.imageUrl = url || "assets/img/logo.png";
								if (
									placeDetails.types &&
									placeDetails.types.includes("veterinary_care")
								)
									element.practiceType = "Veterinary Care";
							}
						}
					}
				);
			});
	}

	function getPetImage(vet: IVetModel) {
		if (vet?.googlePlaceId) {
			return (
				imageMap.find((f) => f.placeId === vet.googlePlaceId)?.url ||
				"assets/img/logo.png"
			);
		} else {
			return "assets/img/logo.png";
		}
	}

	return (
		<div className="flex w-full flex-col">
			<div className="flex flex-col md:flex-row justify-between pb-10 gap-10 md:gap-3">
				<Typography className="font-robotoslab text-18px md:text-22px font-700 leading-21px md:leading-26px text-peeva-black">
					Veterinarian Information
				</Typography>

				<Link to="/client/add-vet" className="text-peeva-blue">
					<Typography className="font-robotoslab text-14px md:text-16px font-700 leading-20px md:leading-22px">
						<img
							src="assets/icons/vets.svg"
							className="fuse-list-item-icon shrink-0 inline"
						/>{" "}
						Add a vet
					</Typography>
				</Link>
			</div>

			{vets.map((vet) => ( 
				<div
					key={vet.id}
					className="flex w-full my-5 flex-col cursor-pointer"
					onClick={() => navigate(`/client/vet-info/profile/${vet.id}`)}
				>
					<div className="flex w-full">
						<div className="flex p-10">
							<img
								className="w-[95px] h-[95px] rounded-[8px]"
								src={vet.imageUrl || "assets/img/logo.png"}
								alt={vet.name}
							/>
						</div>
						<div className="flex flex-col py-10">
							<Typography className="font-robotoslab text-12px md:text-14px font-700 leading-17px md:leading-20px text-peeva-black capitalize mb-3">
								{vet.name} {vet.practiceType}
							</Typography>
							<Typography className="font-robotoslab flex flex-row text-12px md:text-14px text-700 leading-17px md:leading-20px text-peeva-black capitalize items-center mb-3">
								<Rating
									name="vet-rating"
									value={vet.rating || 0}
									precision={0.1}
									readOnly
									className="text-peeva-blue contents"
									sx={{ width: "20px", height: "20px" }}
								/>
								<span className="font-robotoslab text-12px md:text-14px text-400 leading-17px md:leading-20px text-peeva-black capitalize my-1 ml-6">
								{vet.reviews} reviews
							</span>
								
							</Typography>
							<Typography className="font-robotoslab text-12px md:text-14px text-400 leading-17px md:leading-20px text-peeva-black capitalize my-1">
								{vet.phoneNumber}
							</Typography>
							<Typography className="font-robotoslab text-12px md:text-14px text-400 leading-17px md:leading-20px text-peeva-black capitalize my-1">
								{vet.address1}
							</Typography>
						</div>
					</div>
				</div>
			))}
			
		</div>
	);
}

export default PetVetsListing;
