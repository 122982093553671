import FuseUtils from "@fuse/utils/FuseUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
	IApiResponse,
	IForgotPasswordResponse,
} from "@fuse/models/user.models";
import { IPetModel } from "@fuse/models";
import { StringArrayToDelimiterCase } from "type-fest/source/delimiter-case";

class PetService {
	/**
	 * Signs in with the provided email and password.
	 */
	getUserPets = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}pet/user-pets`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getUserPetList = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}pet/user-pet-list`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getPetById = (id: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}Pet/${id}`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	createPet = (data: any) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}Pet/create-pet`, data)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	updatePet = (id: string, data: any) =>
		new Promise((resolve, reject) => {
			axios
				.put(`${process.env.VITE_APP_API_BASE_URL}Pet/${id}`, data)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	updatePetPlan = (id: string, data: any) =>
		new Promise((resolve, reject) => {
			axios
				.put(`${process.env.VITE_APP_API_BASE_URL}Pet/update-plan`, data)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	updatePetProfile = (data: any) =>
		new Promise((resolve, reject) => {
			axios
				.put(`${process.env.VITE_APP_API_BASE_URL}Pet/pet-profile`, data)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getPetPlans = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}pet/plans`)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	cleanUserPetData = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}pet/remove-user-pets`)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data);
				})
				.catch((error) => {
					resolve(error);
				});
		});

	markAsRead = (petId: string) =>
		new Promise((resolve, reject) => {
			axios
				.put(
					`${process.env.VITE_APP_API_BASE_URL}pet/mark-as-found/${petId}`,
					{}
				)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data);
				})
				.catch((error) => {
					resolve(error);
				});
		});

	sendMailFromPetAlert = (obj: any) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}checkout/checkout-lpa`, obj)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data);
				})
				.catch((error) => {
					resolve(error);
				});
		});

	alertByPet = (petId: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL}petalert/alerts/bypet/${petId}`
				)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data);
				})
				.catch((error) => {
					resolve(error);
				});
		});

	getEmailStats = (lpaId: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL}Utilities/${lpaId}/getEmailStats`
				)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data);
				})
				.catch((error) => {
					resolve(error);
				});
		});

	getPetSubscriptionUrl = () =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}pet/microchip-subscriptions`)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data.result);
				})
				.catch((error) => {
					resolve(error);
				});
		});

	upgradePetPlan = (petId: string) =>
		new Promise((resolve, reject) => {
			axios
				.post(
					`${process.env.VITE_APP_API_BASE_URL}checkout/upgrade-subscription/${petId}`,
					{}
				)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data.result);
				})
				.catch((error) => {
					resolve(error);
				});
		});

	getPetProfilesData = (petId: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}pet/pet-profiles/${petId}`)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data.result);
				})
				.catch((error) => {
					resolve(error);
				});
		});
}

const instance = new PetService();

export default instance;
